import React from "react";
import styles from "./index.module.scss";
import { getPublicPath } from "../../util";
import { useNavigate } from "react-router-dom";

const WGY: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <iframe
        className={styles.iframe}
        src="https://jiaxuankj.com/anli/sw/?jump_from=1_05_37_01"
      ></iframe>
      <img
        src={getPublicPath("/imgs/back.png")}
        alt=""
        className={styles.img}
        onClick={() => {
          navigate("/");
        }}
      />
    </>
  );
};

export default WGY;
